import { useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

import Spinner from "../feature/Spinner";
import Translated from "../feature/Translated";
import { useUserData } from "../../context/UserDataContext";
import { useLoginWebautoIntegration } from "../../api-new";

const LoginWebautoPage = () => {
    const { token, login } = useUserData();
    const navigate = useNavigate();
    const location = useLocation();
    const loginIntegrationMutation = useLoginWebautoIntegration();
    const isPending = loginIntegrationMutation.isPending;
    const isError = loginIntegrationMutation.isError;
    const errorMessage = loginIntegrationMutation.error?.message;

    useEffect(() => {
        let params = queryString.parse(location.search);
        let token = decodeURIComponent(params.token);
        loginIntegrationMutation.mutate(
            { token },
            {
                onSuccess: (data) => {
                    login({ token: data.token });
                },
            }
        );
    }, []);

    useEffect(() => {
        if (token) {
            navigate("/", { state: location.state, replace: true });
        }
    }, [token]);

    return (
        <div
            id="login-page"
            className="page-container page-gutter max-width-container"
        >
            <div className="front-center-wrapper">
                <div className="login-form-container fade-in">
                    {isPending && <Spinner />}
                    {isError && (
                        <Alert>
                            <Translated translationKey={errorMessage} />
                        </Alert>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LoginWebautoPage;
